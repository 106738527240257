<template>
  <div class="fixed">
    <div class="circle" @click="showBox">
      <img src="@/assets/img/cart1.png" alt="" />
      <span class="circle_t">{{ total }}</span>
    </div>
    <transition name="fade">
      <div class="cart_box" v-if="isShow" :style="'height:' + maxwidth + 'px'">
        <div v-show="isShowBox">
          <div class="cart_header">
            <div class="top_title">
              <span>购物车</span>
              <span>已选 {{ selectCount }}/{{ total }} 件商品</span>
            </div>
            <img
              src="@/assets/img/downd.png"
              alt=""
              class="cur"
              @click="(isShow = false), (isShowBox = false)"
            />
          </div>
          <!-- <div class="scorll"> -->
          <div>
            <a-spin :spinning="spinning">
              <div class="contents mb25 noCart" v-if="data.length == 0">
                <img src="@/assets/img/no_cart.png" alt="" />
                <span>购物车暂无商品</span>
              </div>
              <a-checkbox-group @change="onChange" v-model="checkedList" v-else>
                <ul class="contents mt16">
                  <li v-for="(item, index) in data" :key="index">
                    <a-checkbox :value="item.cart_id"> </a-checkbox>
                    <img
                      :src="item.store_image"
                      alt="查看详情"
                      class="book_img cur"
                      @click="
                        $router.push({
                          path: 'goodsDetail',
                          query: { id: item.product_id },
                        })
                      "
                    />
                    <div class="book_detail">
                      <div class="flex_s">
                        <span class="line1">{{ item.store_name }}</span>
                        <a-popconfirm
                          placement="rightBottom"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="confirm(item.cart_id)"
                        >
                          <template slot="title">
                            <span>确定要删除吗?</span>
                          </template>
                          <a-icon
                            type="close"
                            style="
                              color: red;
                              font-weight: bold;
                              cursor: pointer;
                            "
                          />
                        </a-popconfirm>
                      </div>
                      <div style="font-size: 12px; color: #999999">
                        已选：{{ item.spec_name }}
                      </div>
                      <div class="flex_s">
                        <span class="price">￥{{ item.price }}</span>
                        <div class="count_box">
                          <img
                            src="@/assets/img/reduce.png"
                            alt=""
                            class="cur"
                            @click="changeCart('reduce', item, index)"
                          />
                          <span>{{ item.cart_num }}</span>
                          <img
                            src="@/assets/img/add.png"
                            alt=""
                            class="cur"
                            @click="changeCart('add', item, index)"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </a-checkbox-group>
            </a-spin>
          </div>
          <div class="flex_s settle">
            <a-checkbox
              @change="changeAll"
              :indeterminate="indeterminate"
              class="font14"
              :checked="checkAll"
            >
              全选
            </a-checkbox>
            <div class="flex">
              <div class="total">
                <div class="total_box">
                  <span>合计</span>
                  <span>￥{{ totalPrice | price }}</span>
                </div>
              </div>
              <a-button type="primary" @click="toPay"> 去结算 </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- <div class="circle">
        <img src="@/assets/img/gotop.png" alt="" @click="goTop" />
      </div> -->
  </div>
</template>

<script>
import { cartNum, delCart } from "@/api/allGoods";
import { price } from "@/utils/filters";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isShow: false,
      isShowBox: false,
      current: 1,
      pageSize: 100,
      // data: [],
      // total: "",
      // totalFreight: "0.00",
      // plainOptions: [], //默认全选的集合
      indeterminate: false,
      checkAll: false,
      checkedList: [],
      selectCount: 0,
      // noFullPrice: 0, // 不满n的运费
      // postageMoney: 0, // 满n包邮
      totalPrice: "0.00",
      // showTotal: 0,
      // spinning: false,
      maxwidth: 620,
    };
  },
  filters: { price },
  created() {
    this.$store.dispatch("cart/getNum");
  },
  computed: {
    ...mapState({
      data: (state) => {
        return state.cart.data;
      },
      spinning: (state) => {
        return state.cart.loading;
      },
      plainOptions: (state) => {
        return state.cart.plainOptions; //默认全选的集合
      },
      showTotal: (state) => {
        return state.cart.showTotal;
      },
      total: (state) => {
        return state.cart.total;
      },
    }),
    // ...mapActions('cart',['getList'])
  },
  watch: {
    checkedList: {
      handler() {
        this.changeTotal();
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.setShow);
  },
  destroyed() {
    window.removeEventListener("resize", this.setShow);
  },
  methods: {
    setShow() {
      if (document.body.offsetHeight < this.maxwidth + 60) {
        this.maxwidth = document.body.offsetHeight - 60;
      } else {
        this.maxwidth =
          this.maxwidth <= 620
            ? document.body.offsetHeight - 60
            : this.maxwidth;
      }
    },
    goTop() {
      this.$router.go(0);
    },
    toPay() {
      if (this.checkedList.length == 0) {
        this.$message.error("请选择结算的商品");
        return false;
      }
      this.$router.push({
        path: "submitOrder",
        query: { cartId: this.checkedList, new: 0 },
      });
    },
    confirm(id) {
      delCart({
        ids: [id],
      }).then(() => {
        this.clear();
        this.$store.dispatch("cart/getList", {
          page: this.current,
          limit: this.pageSize,
        });
        this.$store.dispatch("cart/getNum");
        this.$emit("getCartNum");
      });
    },
    clear() {
      this.checkedList = [];
      // this.plainOptions = [];
      this.checkAll = false;
      this.indeterminate = false;
      this.totalPrice = "0.00";
      // this.totalFreight = "0.00";
    },
    changeTotal() {
      let price = 0;
      this.data.map((items) => {
        if (this.checkedList.indexOf(items.cart_id) != -1) {
          price += parseFloat(items.price) * items.cart_num;
        }
      });
      this.totalPrice = price;
    },
    changeCart(title, list, index) {
      if (this.data[index].cart_num == 1 && title == "reduce") {
        this.$message.warning("数量最少为一");
        return false;
      }
      if (this.data[index].cart_num >= 1) {
        let num =
          title == "reduce"
            ? this.data[index].cart_num - 1
            : this.data[index].cart_num + 1;
        cartNum({
          id: this.data[index].cart_id,
          number: num,
        }).then(() => {
          if (title == "add") {
            this.data.forEach((item, i) => {
              if (i == index) {
                console.log(item);
                this.$set(item, "cart_num", num);
              }
            });
          } else if (title == "reduce") {
            this.data.forEach((item, i) => {
              if (i == index && item.cart_num > 1) {
                this.$set(item, "cart_num", num);
              }
            });
          }
          this.changeTotal();
        });
      }
      this.changeTotal();
    },
    onChange(value) {
      this.checkedList = value;
      this.indeterminate =
        !!value.length && value.length < this.plainOptions.length;
      this.checkAll = value.length === this.plainOptions.length;
      this.selectCount = value.length;
    },
    changeAll(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
        selectCount: e.target.checked ? this.plainOptions.length : 0,
      });
    },
    showBox() {
      this.$store
        .dispatch("cart/getList", { page: this.current, limit: this.pageSize })
        .catch((err) => {
          this.$message.error(err);
        });
      this.setShow();

      this.isShow = true;
      this.selectCount = 0;
      this.checkedList = [];
      setTimeout(() => {
        this.isShowBox = true;
      }, 200);
    },
    // getNum() {
    //   cartCount().then((res) => {
    //     if (res.data.status == 200) {
    //       this.total = res.data.data.count;
    //       this.showTotal = res.data.data.productCount;
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },
    // getList(page, limit) {
    //   this.clear();

    //   this.spinning = true;
    //   cartList({
    //     page: page || this.page,
    //     limit: limit || this.limit,
    //   }).then((res) => {
    //     this.spinning = false;
    //     if (res.data.status == 200) {
    //       if (
    //         res.data.data.list.productInfo &&
    //         res.data.data.list.productInfo.length != 0
    //       ) {
    //         this.data = res.data.data.list.productInfo;
    //         this.data.map((item) => {
    //           this.plainOptions.push(item.cart_id);
    //         });
    //         // this.postageMoney = res.data.data.list.postageMoney;
    //         // this.noFullPrice = res.data.data.list.freightPrice;
    //       } else {
    //         this.data = [];
    //       }
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.total {
  display: flex;
  margin-right: 16px;
  flex-direction: column;
  align-items: flex-end;
}
.freight {
  font-size: 12px;
  color: #999999;
  font-weight: bold;
}
.total_box {
  font-size: 14px;
  font-weight: bold;
}
.total_box span:last-of-type {
  color: #3ab887;
}
.total_box span:first-of-type {
  color: #333333;
}
.flex {
  display: flex;
  align-items: center;
}
.font14 {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
.cur {
  cursor: pointer;
}
.count_box {
  display: flex;
  align-items: center;
}
.count_box img {
  width: 20px;
  height: 20px;
}
.count_box span {
  margin: 0 16px;
  font-size: 12px;
  color: #999999;
  font-weight: bold;
}
.price {
  font-size: 14px;
  font-weight: bold;
  color: #3ab887;
}
.book_detail {
  width: 176px;
  margin-left: 8px;
}
.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}
.flex_s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_img {
  width: 12px;
  height: 12px;
}
.contents li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.book_img {
  width: 64px;
  height: 64px;
  margin-left: 12px;
  object-fit: contain;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contents {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-left: 14px;
  overflow-x: auto;
}
.mt16 {
  margin-top: 16px;
}
.mb25 {
  margin-bottom: 25px;
}
.cart_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
  padding: 16px 8px 8px 14px;
  position: sticky;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: 2;
}

.cart_header img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.top_title span:first-of-type {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-right: 8px;
}

.top_title span:last-of-type {
  font-size: 12px;
  color: #999999;
}
.circle {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  /* background-color: #fff; */
  background-color: #3ab887;
  box-shadow: 0px 5px 10px 0px rgba(58, 184, 135, 0.1);
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.circle_t {
  position: absolute;
  background-color: red;
  color: #fff;
  padding: 0 5px;
  border-radius: 8px;
  font-size: 12px;
  top: -3px;
  right: 0;
}
.circle img {
  width: 24px;
  height: 24px;
}
.cart_box::-webkit-scrollbar {
  width: 6px !important;
  height: 48px !important;
  background-color: #fff !important;
  /* display: none; */
}
.cart_box::-webkit-scrollbar-thumb {
  width: 6px !important;
  height: 48px !important;
  background-color: #dfdfdf !important;
}
.cart_box {
  width: 312px;
  min-height: 300px;
  max-height: 620px !important;
  overflow-y: scroll;
  /* height: 620px; */
  border: 2px solid #3ab887;
  background-color: #fff;
  position: absolute;
  right: 60px;
  bottom: 0px;
  z-index: 2;
  /* padding: 16px 8px 19px 14px; */
  box-sizing: border-box;
}
.fade-enter-active,
.fade-leave-active {
  width: 312px;
  height: 620px;
  opacity: 1;
  transition: all 0.1s linear;
}
.fade-enter,
.fade-leave-to {
  width: 0;
  height: 0;
  opacity: 0;
}
.contents::-webkit-scrollbar {
  width: 4px !important;
  height: 48px !important;
  background-color: #fff !important;
}
.contents::-webkit-scrollbar-thumb {
  width: 4px !important;
  height: 48px !important;
  background-color: #eeeeee !important;
}
.noCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
}
.noCart img {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}
.scorll {
  height: 521px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex1 {
  flex: 1;
  flex-shrink: 0;
}
.settle {
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
  padding: 8px 8px 6px 14px;
}
</style>
